/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ALL_POP_UP = gql`
  query AllPopUp {
    allPopUp {
      id
      title
      image
      url
    }
  }
`;

export const ALL_PARTNER = gql`
  query AllPartner {
    allPartner {
      partnerName
      partnerAlt
      partnerLogoM
      partnerUrl
      isActive
    }
  }
`;

export const ALL_BANNER = gql`
  query AllBanner {
    allBanner {
      id
      bannerUrl
      bannerAlt
      bannerTitle
      image
      imageMobile
    }
  }
`;

export const ALL_PRODUCT = gql`
  query AllProduct($ordering: String, $limit: Int, $offset: Int) {
    allProduct(ordering: $ordering, limit: $limit, offset: $offset) {
      data {
        id
        created
        highlightText
        productOrder
        name
        code
        description
        shortDescription
        productCategory {
          id
          name
        }
        discountRate
        unit
        volume
        deliveryType
        origin
        packageType
        expiryDate
        images {
          id
          product {
            id
          }
          image
        }
        options {
          id
          price
          discountedPrice
          onSale
          name
          shortName
          longName
        }
      }
      totalCount
    }
  }
`;

export const BEST_PRODUCTS = gql`
  query BestProducts($ordering: String, $limit: Int, $offset: Int) {
    bestProducts(ordering: $ordering, limit: $limit, offset: $offset) {
      data {
        id
        created
        highlightText
        name
        code
        images {
          image
        }
        options {
          id
          price
          discountedPrice
          onSale
        }
      }
      totalCount
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      id
      username
      name
    }
  }
`;

export const DEVELOPMENT_DIARY = gql`
  query DevelopmentDiary {
    developmentDiary {
      id
      developedMenu
      accumulatedMeal
      healthData
    }
  }
`;
