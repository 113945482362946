import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { Skeleton } from 'antd';

import * as Queries from './queries';

import MainBlueBottomImg from '../../assets/images/MainBlueBottomImg.png';

const MainMenuDevelopLog = () => {
  const { data: allDevelopmentDiaryData, loading } = useQuery(Queries.DEVELOPMENT_DIARY);
  if (loading) {
    return <Skeleton active />;
  }
  return (
    //
    <>
      <MenuDevelopLog>
        <MenuDevelopLogTitle>
          <span>맛있는 개발일지</span>
          <MenuDevelopLogBottomImg>
            <img src={MainBlueBottomImg} alt="MainBlueBottomImg" />
          </MenuDevelopLogBottomImg>
        </MenuDevelopLogTitle>
        <MenuDevelopLogContent>
          <span>
            맛있저염은 데이터 기반 식사요법 전문기업으로 한 끼 식사의 즐거움을 되찾아 드립니다.
          </span>
        </MenuDevelopLogContent>
        <DevelopLog>
          <YellodwTitle>개발 메뉴</YellodwTitle>
          <LogCount>
            {allDevelopmentDiaryData?.developmentDiary?.developedMenu.toLocaleString()}
          </LogCount>
        </DevelopLog>
        <DevelopLog>
          <YellodwTitle>누적 끼니</YellodwTitle>
          <LogCount>
            1,164,958
            {/* {allDevelopmentDiaryData.developmentDiary.accumulatedMeal.toLocaleString()} */}
          </LogCount>
        </DevelopLog>
        <DevelopLog>
          <YellodwTitle>건강 데이터</YellodwTitle>
          <LogCount>
            {allDevelopmentDiaryData?.developmentDiary?.healthData.toLocaleString()}
          </LogCount>
        </DevelopLog>
        <BaseDate>*2021년 10월 기준</BaseDate>
      </MenuDevelopLog>
    </>
  );
};

const MenuDevelopLog = styled.div`
  width: 100%;
  height: 61.2rem;
  background-color: #f2f2f2;
  padding: 5.4rem 32rem 8rem;
  text-align: center;
  margin-bottom: 8rem;
  ${props => props.theme.device.sm} {
    margin-top: 4.8rem;
    height: 38.3rem;
    padding: 3.4rem 5.1rem 1.1rem 2.4rem;
    margin-bottom: 0;
  }
`;
const MenuDevelopLogTitle = styled.div`
  font-family: TmoneyRoundWindRegular;
  font-weight: 800;
  font-stretch: normal;
  color: #222;
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 3.7rem;
  position: relative;
  span {
    position: relative;
    z-index: 1;
  }
  ${props => props.theme.device.sm} {
    font-size: 1.8rem;
    margin-bottom: 0;
    height: 1.8rem;
  }
`;
const MenuDevelopLogBottomImg = styled.div`
  object-fit: contain;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 2.4rem;
  img {
    width: 16.8rem;
    height: 1.2rem;
    opacity: 0.8;
  }

  ${props => props.theme.device.sm} {
    height: 0.8rem;
    img {
      width: 12.7rem;
      height: 1.11rem;
    }
  }
`;
const MenuDevelopLogContent = styled.div`
  text-align: center;
  font-family: Spoqa Han Sans;
  font-size: 2.4rem;
  padding: 0 2rem;
  margin-bottom: 3.2rem;
  span {
    width: 100%;
  }
  line-height: 1.33;
  ${props => props.theme.device.sm} {
    margin-top: 2.1rem;
    padding: 0;
    font-size: 14px;
    color: #444;
    margin-bottom: 0.4rem;
  }
  span {
    width: 100%;
  }
`;

const DevelopLog = styled.div`
  height: 8rem;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #cecece;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 2.2rem 9.7rem 2.2rem 5.1rem;
  margin-bottom: 1.6rem;
  ${props => props.theme.device.sm} {
    height: 4.8rem;
    line-height: 4.8rem;
    padding: 0 2.4rem 3.4rem;
    margin-top: 1.6rem;
    margin-bottom: 0;
  }
`;
const YellodwTitle = styled.div`
  font-family: TmoneyRoundWindRegular;
  font-weight: 800;
  color: #ff7e1f;
  font-size: 2.4rem;
  ${props => props.theme.device.sm} {
    font-size: 1.4rem;
    height: 100%;
  }
`;
const LogCount = styled.div`
  font-family: Spoqa Han Sans;
  font-weight: bold;
  color: #222;
  font-size: 2.4rem;
  ${props => props.theme.device.sm} {
    height: 100%;

    font-size: 17px;

    text-align: center;
  }
`;
const BaseDate = styled.div`
  text-align: right;
  font-family: Spoqa Han Sans;
  color: #ff7e1f;
  font-size: 2rem;
  ${props => props.theme.device.sm} {
    font-size: 1.4rem;
    margin-top: 1.2rem;
  }
`;
export default MainMenuDevelopLog;
