import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import Slider from 'react-slick';
import { talktalkData } from '../LabData';

import MainYellowBottomImg from '../../assets/images/MainYellowBottomImg.png';
import arrow from '../../assets/images/GoToReviewImg.png';

const MainTips = () => {
  const [data, setData] = useState(talktalkData);

  useEffect(() => {
    if (talktalkData) {
      const deep = JSON.parse(JSON.stringify(talktalkData));
      deep.reverse();
      setData(deep.slice(0, 6));
    }
  }, []);

  // Todo. slideToshow 갯수 3개 도달할때까지 수동
  const settings = {
    infinite: data.length > 3,
    slidesToShow: 3
  };
  return (
    // 매주 추가될 페이지
    <>
      <TipsContainer>
        <TipsTitle>
          <span>맛있저염 식사연구소</span>
          <TipBottomImg>
            <img src={MainYellowBottomImg} alt="MainTipsBottomImg" />
          </TipBottomImg>
        </TipsTitle>
        <Tips>
          {/* 모바일 */}
          {data.map(item => {
            return (
              <Link key={item.id} href={item.url}>
                <MobileTip>
                  <MobileTipImg background={item.img} />
                  <TipContent>{item.desc}</TipContent>
                </MobileTip>
              </Link>
            );
          })}

          {/* 웹 버전 */}
          <TipSlider {...settings}>
            {data.map(item => {
              return (
                <Link key={item.id} href={item.url}>
                  <Tip>
                    <WebTipImg background={item.img} />
                    <TipContent>{item.desc}</TipContent>
                  </Tip>
                </Link>
              );
            })}
          </TipSlider>
        </Tips>
        {/* <MoveToLabWrap>
          <Link href="/lab">
            <MoveToLab>
              더 많은 이야기 보러가기 &nbsp;
              <img src={arrow} alt="영양톡톡 보러가기" />
            </MoveToLab>
          </Link>
        </MoveToLabWrap> */}
      </TipsContainer>
    </>
  );
};
const TipsContainer = styled.div`
  text-align: center;
  span {
    font-size: 2.4rem;
    font-family: TmoneyRoundWindRegular;
    font-weight: 800;
    font-stretch: normal;
    color: #222;
  }
  ${props => props.theme.device.sm} {
    text-align: left;
    width: 100%;
    padding-left: 1.6rem;
    span {
      font-size: 18px;

      padding-left: 0.42rem;
    }
    img {
      width: 14.4rem;
      object-fit: contain;
      /* opacity: 0.7; */
    }
  }
`;

const TipsTitle = styled.div`
  text-align: center;
  position: relative;
  span {
    position: relative;
    z-index: 1;
  }
  ${props => props.theme.device.sm} {
    text-align: left;
  }
`;

const TipBottomImg = styled.div`
  object-fit: contain;
  width: 100%;
  position: absolute;
  bottom: 0;
  img {
    width: 22rem;
    height: 1.2rem;
    opacity: 0.7;
  }
  ${props => props.theme.device.sm} {
    img {
      width: 16rem;
      height: 1.1rem;
    }
  }
`;
const Tips = styled.div`
  a {
    text-decoration: none;
  }
  ${props => props.theme.device.sm} {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
    margin-top: 2.3rem;
    margin-bottom: 0;
    padding-left: 0;
  }
`;

const Tip = styled.div`
  border-radius: 4px;
  border: solid 1px #cecece;
  width: 35rem;
  height: 43rem;
  cursor: pointer;
  margin-right: 1.6rem;
  ${props => props.theme.device.sm} {
    flex-shrink: 0;
    display: inline-block;
    width: 24rem;
    height: 24.8rem;
    margin-right: 1.2rem;
  }
`;
const WebTipImg = styled.div`
  width: 35rem;
  height: 35rem;
  img {
    width: 100%;
    height: 100%;
  }

  background-image: url(${props => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${props => props.theme.device.sm} {
    display: none;
  }
`;

const TipTitle = styled.div`
  font-weight: bold;
  font-stretch: normal;
  color: #222;
  text-align: left;
  font-size: 2rem;
  margin-top: 1.7rem;
  margin-left: 1.6rem;
  font-family: Spoqa Han Sans;

  ${props => props.theme.device.sm} {
    width: 100%;
    padding-left: 1.2rem;
    margin-top: 0.7rem;
    font-size: 1.6rem;
    margin-left: 0;
    span {
      padding-left: 0;
    }
  }
`;
const TipContent = styled.p`
  color: #444;
  white-space: normal;
  font-family: Spoqa Han Sans;
  font-size: 1.8rem;
  letter-spacing: 0.6px;
  margin: 0 3.3rem 0 1.6rem;
  text-align: left;
  margin-top: 1rem;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${props => props.theme.device.sm} {
    margin-top: 0.8rem;
    padding-right: 2.4rem;
    padding-left: 1.2rem;
    font-size: 1.4rem;
    margin: 0;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const MobileTipImg = styled.div`
  width: 23.8rem;
  height: 23.8rem;
  display: none;

  background-image: url(${props => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${props => props.theme.device.sm} {
    display: inline-block;
    width: 23.8rem;
    height: 23.8rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const TipSlider = styled(Slider)`
  margin-top: 6.6rem;
  margin-bottom: 8.8rem;
  button.slick-arrow.slick-prev {
    z-index: 1;
    left: -4%;
    width: 6.4rem;
    height: 6.4rem;
  }
  button.slick-arrow.slick-next {
    z-index: 1;
    right: -2%;
    width: 6.4rem;
    height: 6.4rem;
  }
  button.slick-arrow.slick-prev::before {
    color: #cecece;
    font-size: 6.4rem;
    opacity: 1;
  }
  button.slick-arrow.slick-next::before {
    color: #cecece;
    font-size: 6.4rem;
    opacity: 1;
  }
  ${props => props.theme.device.sm} {
    display: none;
  }
`;

const MobileTip = styled.div`
  display: none;

  ${props => props.theme.device.sm} {
    border-radius: 4px;
    border: solid 1px #cecece;
    flex-shrink: 0;
    display: inline-block;
    width: 24rem;
    height: 29rem;
    margin-right: 1.2rem;
  }
`;

const MoveToLabWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4.8rem;
`;

const MoveToLab = styled.div`
  width: 50rem;
  height: 7rem;
  border-radius: 4px;
  /* border: 1px solid #cecece; */
  font-size: 2.1rem;
  line-height: 7rem;
  font-family: TmoneyRoundWindRegular;
  font-weight: 800;
  text-align: center;
  color: #000;
  cursor: pointer;
  img {
    height: 2.1rem;
  }

  ${props => props.theme.device.sm} {
    font-size: 1.4rem;
    margin-top: 1.2rem;
    height: 4.8rem;
    line-height: 4.8rem;

    img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

export default MainTips;
