import React from 'react';
import { Carousel } from 'antd';
import styled from 'styled-components';

import { useQuery } from '@apollo/react-hooks';
import * as Queries from './queries';

const MainBanner = () => {
  const { data: allBannerData } = useQuery(Queries.ALL_BANNER);

  return (
    //
    <>
      {/* 모바일 carousel */}
      <MobileCarousel>
        <Carousel dots="false" autoplay autoplaySpeed={4400}>
          {allBannerData?.allBanner?.map(item => {
            return (
              <MobileCarouselImg key={item.id}>
                <a href={item.bannerUrl}>
                  <img
                    src={`https://static.microsalts.com/media/${item.imageMobile}`}
                    alt={item.bannerAlt}
                  />
                </a>
              </MobileCarouselImg>
            );
          })}
        </Carousel>
      </MobileCarousel>
      {/* 웹뷰 carousel */}
      <WebCarousel>
        <Carousel autoplay autoplaySpeed={4400}>
          {allBannerData?.allBanner?.map(item => {
            return (
              <WebCarouselImg key={item.id}>
                <a href={item.bannerUrl}>
                  <img src={item.image} alt={item.bannerAlt} />
                </a>
              </WebCarouselImg>
            );
          })}
        </Carousel>
      </WebCarousel>
    </>
  );
};

const MobileCarousel = styled.div`
  display: none;
  ${props => props.theme.device.sm} {
    display: block;
  }
`;
const MobileCarouselImg = styled.div`
  ${props => props.theme.device.sm} {
    img {
      width: 100%;
      text-align: center;
      object-fit: contain;
    }
  }
`;

const WebCarousel = styled.div`
  width: 100%;
  /* height: 40rem; */
  margin-bottom: 2rem;
  .ant-carousel > .slick-slider > .slick-dots > li > button:before {
    content: none;
  }
  ${props => props.theme.device.sm} {
    display: none;
    margin-bottom: 0;
  }
`;

const WebCarouselImg = styled.div`
  cursor: pointer;
  img {
    object-fit: contain;
    width: 112rem;
    text-align: center;
  }
`;

export default MainBanner;
