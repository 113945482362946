import React from 'react';
import _ from 'lodash';

import styled from 'styled-components';
import Slider from 'react-slick';
import Link from 'next/link';

import MainYellowBottomImg from '../../assets/images/MainYellowBottomImg.png';
import MainPageReviewMiniImg from '../../assets/images/MainPageReviewMiniImg.png';
import MainpageReviewStars from '../../assets/images/MainpageReviewStars.png';
import MainpageSeeReview from '../../assets/images/MainpageSeeReview.png';
import MainpageReviewLine from '../../assets/images/MainpageReviewLine.png';
import GoToReviewImg from '../../assets/images/GoToReviewImg.png';
import MobileMainpageReviewStars from '../../assets/images/MobileMainpageReviewStars.png';

const MainReview = ({ allReviewsDataJson, totalReviewsDataJson }) => {
  const reviewSettings = {
    arrows: true,
    autoplay: true,
    dots: true,
    infinite: true,
    pauseOnFocus: true,
    speed: 5000,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 2,
    textAlign: 'center'
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />
  };

  // 제플린 수정필요 나중에 다시 살려야함

  // function SampleNextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         display: 'block',
  //         width: '6.4rem',
  //         height: '6.4rem',
  //         fontSize: '6.4rem'
  //       }}
  //       onClick={onClick}
  //     />
  //   );
  // }

  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
  // }
  const totalReviewsData = _.get(totalReviewsDataJson, 'data');
  const allReviewsData = _.get(allReviewsDataJson, 'data');

  const totalReviews = _.get(totalReviewsData, 'allReviews');
  const allReviews = _.get(allReviewsData, 'allReviews');
  const uploadImageReviews = _.filter(allReviews, function(o) {
    return o.photoUrl1;
  });
  const allReviewsFilter = _(_.filter(uploadImageReviews, { stars: 5 }))
    .orderBy('created', 'desc')
    .take(7)
    .value();
  const allReviewsLength = _.get(allReviews, 'length');
  const totalReviewsLength = _.get(totalReviews, 'length');

  const ReviewCount = 487 + totalReviewsLength;
  const nameHidden = strName => {
    if (_.size(strName) > 2) {
      const originName = _.split(strName, '');
      originName.forEach(function(name, i) {
        if (i === 0 || i === originName.length - 1) return;
        originName[i] = '*';
      });
      const joinName = _.join(originName);
      return joinName && joinName.replace(/,/g, '');
    } else {
      const pattern = /.$/; // 정규식
      return strName && strName.replace(pattern, '*');
    }
  };
  return (
    //
    <>
      <Container>
        <CustomerReviewTitle>
          <span>맛저피플 고객리뷰</span>
          <CustomerReviewBottomImg>
            <img src={MainYellowBottomImg} alt="MainYellowBottomImg" />
          </CustomerReviewBottomImg>
        </CustomerReviewTitle>
        <EachReviews>
          <Slider {...reviewSettings}>
            {allReviewsFilter.map(item => {
              return (
                <EachReview key={item.id}>
                  <ReviewImg>
                    <img src={`https://microsalts-media.s3.ap-northeast-2.amazonaws.com/media/images/reviews/${item.photoUrl1}`} alt={item.tags} />
                  </ReviewImg>
                  <ReviewNickname>
                    <span>{nameHidden(item.order?.applicantName)}</span>
                  </ReviewNickname>
                  <Reviewcontent>{item.contents}</Reviewcontent>
                  <Line />
                  <ReviewProduct>
                    <MiniReviewImg>
                      <img src={MainPageReviewMiniImg} alt="MainPageReviewMiniImg" />
                    </MiniReviewImg>
                    <ProductAndStars>
                      <ReviewProductName>{item.order?.name}</ReviewProductName>
                      <ReviewStars>
                        <img src={MainpageReviewStars} alt="MainpageReviewStars" />
                        <img src={MainpageReviewStars} alt="MainpageReviewStars" />
                        <img src={MainpageReviewStars} alt="MainpageReviewStars" />
                        <img src={MainpageReviewStars} alt="MainpageReviewStars" />
                        <img src={MainpageReviewStars} alt="MainpageReviewStars" />
                        <ReviewStarsNumber>{item.stars}</ReviewStarsNumber>
                      </ReviewStars>
                    </ProductAndStars>
                  </ReviewProduct>
                </EachReview>
              );
            })}
          </Slider>
        </EachReviews>
        <SeeReviews>
          <SeeReviewsFlex>
            <PencilImg>
              <img src={MainpageSeeReview} alt="MainpageSeeReview" />
            </PencilImg>
            <ReviewLine>
              <img src={MainpageReviewLine} alt="MainpageReviewLine" />
            </ReviewLine>
            <Link href="/reviews">
              <TotalReview>
                <TotalCount>
                  {/* {ReviewCount.toLocaleString()} */}
                  999 +
                </TotalCount>{' '}
                건의 후기 보러가기{' '}
                <GoToReview>
                  <img src={GoToReviewImg} alt="GoToReviewImg" />
                </GoToReview>
              </TotalReview>
            </Link>
          </SeeReviewsFlex>
        </SeeReviews>

        {/* 모바일 */}

        <MobileEachReviews>
          {allReviewsFilter.map(item => {
            return (
              <MobileEachReview key={item.id}>
                <MobileEachReviewFlex>
                  <MobileEachReviewImg>
                    <img src={`https://microsalts-media.s3.ap-northeast-2.amazonaws.com/media/images/reviews/${item.photoUrl1}`} alt={item.tags} />
                  </MobileEachReviewImg>
                  <MobileEachReviewContent>
                    <MobileReviewStars>
                      <img src={MobileMainpageReviewStars} alt="MobileMainpageReviewStars" />
                      <img src={MobileMainpageReviewStars} alt="MobileMainpageReviewStars" />
                      <img src={MobileMainpageReviewStars} alt="MobileMainpageReviewStars" />
                      <img src={MobileMainpageReviewStars} alt="MobileMainpageReviewStars" />
                      <img src={MobileMainpageReviewStars} alt="MobileMainpageReviewStars" />
                      <MobileReviewStarsNumber>{item.stars}</MobileReviewStarsNumber>
                    </MobileReviewStars>
                    <MobileReviewProduct>{item.order?.name}</MobileReviewProduct>
                    <MobileReviewName>{nameHidden(item.order?.applicantName)}</MobileReviewName>
                    <MobileReviewContent>{item.contents}</MobileReviewContent>
                  </MobileEachReviewContent>
                </MobileEachReviewFlex>
              </MobileEachReview>
            );
          })}
        </MobileEachReviews>
        <MobileSeeReviewsContainer>
          <MobileSeeReviews>
            <SeeReviewsFlex>
              <MobilePencilImg>
                <img src={MainpageSeeReview} alt="MainpageSeeReview" />
              </MobilePencilImg>
              <MobileReviewLine>
                <img src={MainpageReviewLine} alt="MainpageReviewLine" />
              </MobileReviewLine>
              <Link href="/reviews">
                <MobileTotalReview>
                  <TotalCount>
                    {/* {ReviewCount.toLocaleString()} */}
                    999 +
                  </TotalCount>{' '}
                  건의 후기 보러가기{' '}
                  <MobileGoToReview>
                    <img src={GoToReviewImg} alt="GoToReviewImg" />
                  </MobileGoToReview>
                </MobileTotalReview>
              </Link>
            </SeeReviewsFlex>
          </MobileSeeReviews>
        </MobileSeeReviewsContainer>
        <MobileReviewSmallContent>
          <span>*실제 고객의 생생한 후기를 확인해보세요!</span>
        </MobileReviewSmallContent>
      </Container>
    </>
  );
};
const Container = styled.div`
  width: 100%;
  height: 95.4rem;
  background-color: #f2f2f2;
  margin-bottom: 8.8rem;
  padding: 5.4rem 1.6rem 6.5rem;

  ${props => props.theme.device.sm} {
    height: 38.3rem;
    padding: 3.2rem 0;
    margin-bottom: 4.8rem;
  }
`;
const CustomerReviewTitle = styled.div`
  font-size: 2.4rem;
  font-family: TmoneyRoundWindRegular;
  font-weight: 800;
  color: #222;
  text-align: center;
  margin-bottom: 1.9rem;
  position: relative;
  text-align: center;
  span {
    position: relative;
    z-index: 1;
  }
  ${props => props.theme.device.sm} {
    /* display: none; */
    font-size: 1.8rem;
  }
`;
const CustomerReviewBottomImg = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 2.4rem;
  img {
    width: 19.2rem;
    height: 1.2rem;
  }
  ${props => props.theme.device.sm} {
    height: 1.8rem;
    img {
      width: 14.4rem;
      height: 0.88rem;
    }
  }
`;
const EachReviews = styled.div`
  width: 100%;
  ${props => props.theme.device.sm} {
    display: none;
  }
  .slick-slider > .slick-arrow {
    z-index: 1;
    display: none;
  }
  .slick-slider > .slick-arrow::before {
    width: 6.4rem;
    height: 6.4rem;
    font-size: 6.4rem;
    color: black;
    display: none;
  }
`;
const EachReview = styled.div`
  width: 35.2rem !important;
  height: 65rem;
  padding: 1.6rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cecece;
  background-color: #fff;
  img {
    border-radius: 4px;
  }
  ${props => props.theme.device.sm} {
    display: none;
  }
`;
const ReviewImg = styled.div`
  width: 32rem;
  height: 32rem;
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  ${props => props.theme.device.sm} {
  }
`;
const ReviewNickname = styled.div`
  margin-top: 1.6rem;
  font-family: Spoqa Han Sans;
  font-size: 2rem;
  font-weight: bold;
  color: #444;
  line-height: 1.45;
  letter-spacing: 0.6px;
  text-align: left;
  color: #444;
  height: 2.9rem;
`;
const Reviewcontent = styled.p`
  margin-top: 0.8rem;
  font-family: Spoqa Han Sans;
  font-size: 2rem;
  padding-right: 1.4rem;
  height: 11.6rem;
  display: block;
  /* white-space: pre; */
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Line = styled.hr`
  /* margin-top: 5.3rem; */
  border: solid 1px #cecece;
`;

const ReviewProduct = styled.div`
  display: flex;
  margin-top: 1.6rem;
`;

const MiniReviewImg = styled.div`
  width: 8.8rem;
  height: 8.8rem;
  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;

const ProductAndStars = styled.div`
  margin-left: 1.6rem;
`;

const ReviewProductName = styled.div`
  width: 20rem;
  height: 2.5rem;
  font-family: Spoqa Han Sans;
  font-size: 1.7rem;
  letter-spacing: 0.51px;
  text-align: left;
  color: #444;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ReviewStars = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  margin: 0 0.3rem;
  margin-top: 1.2rem;

  img {
    width: 100%;
    height: 100%;
  }
`;

const ReviewStarsNumber = styled.div`
  margin-left: 1.3rem;
  font-family: Spoqa Han Sans;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5rem;
  letter-spacing: normal;
  text-align: left;
  color: #3c3c3c;
`;

const SeeReviews = styled.div`
  width: 47.7rem;
  height: 7.2rem;
  margin: 4.8rem 30.5rem 0 30.6rem;
  padding: 0.9rem 5rem 0.9rem 1.8rem;
  border-radius: 4px;
  border: solid 1px #cecece;
  background-color: #fff;
  ${props => props.theme.device.sm} {
    display: none;
  }
`;

const SeeReviewsFlex = styled.div`
  display: flex;
`;

const PencilImg = styled.div`
  img {
    width: 5.17rem;
    height: 3.34rem;
    margin: 1.01rem 1.73rem 1.05rem 0;
  }
`;

const ReviewLine = styled.div`
  img {
    width: 0.1rem;
    height: 5.4rem;
    margin: 0 4.8rem 0 1.73rem;
    object-fit: contain;
    border: solid 1px #cecece;
    background-color: #fff;
  }
`;

const TotalReview = styled.div`
  display: flex;
  font-family: TmoneyRoundWindRegular;
  font-size: 2.1rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
  height: 0.21rem;
  padding-top: 1rem;
`;
const TotalCount = styled.div`
  color: #ff7e1f;
  margin-right: 1.2rem;
  /* width: 6.3rem; */
`;

const GoToReview = styled.div`
  margin-left: 1.69rem;
  width: 0.9rem;
  height: 1.8rem;
  img {
    width: 100%;
    height: 100%;
  }
`;
const MobileEachReviews = styled.div`
  display: none;
  ${props => props.theme.device.sm} {
    width: 100%;
    /* height: 23.7rem; */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
    display: inline-block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 1.2rem;
  }
`;

const MobileEachReview = styled.div`
  width: 31.9rem;
  height: 17.6rem;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #cecece;
  background-color: #fff;
  padding: 1.6rem 2.7rem 3.1rem 1.6rem;
  display: inline-block;
  margin: 0 0.5rem;
`;
const MobileEachReviewFlex = styled.div`
  display: flex;
`;
const MobileEachReviewImg = styled.div`
  width: 8rem;
  height: 8rem;
  img {
    width: 100%;
    height: 100%;
  }
`;
const MobileEachReviewContent = styled.div`
  margin-left: 1.6rem;
`;

const MobileReviewStars = styled.div`
  display: flex;
  width: 100%;
  height: 2rem;
  img {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.2rem;
  }
`;
const MobileReviewStarsNumber = styled.div`
  margin-left: 0.8rem;
  font-size: 1.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6rem;
  letter-spacing: normal;
  text-align: left;
  color: #3c3c3c;
`;

const MobileReviewProduct = styled.div`
  margin-top: 0.5rem;
  height: 2rem;
  white-space: normal;
  width: 15rem;
  font-size: 1.4rem;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const MobileReviewName = styled.div`
  margin-top: 1.6rem;
  height: 2rem;
  font-family: Spoqa Han Sans;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  text-align: left;
  color: #b2b2b2;
`;
const MobileReviewContent = styled.div`
  margin-top: 0.8rem;
  height: 4rem;
  font-family: Spoqa Han Sans;
  font-size: 1.4rem;
  color: #444;
  width: 18rem;
  height: 4rem;
  /* white-space: normal; */
  display: block;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const MobileSeeReviewsContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const MobileSeeReviews = styled.div`
  width: 31.9rem;
  height: 4.8rem;
  padding: 0.6rem 0 0.6rem 1.2rem;
  border-radius: 4px;
  border: solid 1px #cecece;
  background-color: #fff;
  display: none;
  ${props => props.theme.device.sm} {
    display: inline-block;
  }
`;
const MobilePencilImg = styled.div`
  width: 3.46rem;
  height: 2.23rem;
  margin-top: 0.6rem;
  img {
    width: 100%;
    height: 100%;
  }
`;

const MobileReviewLine = styled.div`
  img {
    width: 0.1rem;
    height: 3.6rem;
    margin: 0 3.2rem 0 1.14rem;
    object-fit: contain;
    border: solid 1px #cecece;
    background-color: #fff;
  }
`;

const MobileTotalReview = styled.div`
  display: flex;
  font-family: TmoneyRoundWindRegular;

  font-size: 1.4rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-top: 0.6rem;
`;

const MobileGoToReview = styled.div`
  margin-left: 1.1rem;
  width: 0.6rem;
  height: 1.2rem;
  img {
    width: 100%;
    height: 100%;
  }
`;

const MobileReviewSmallContent = styled.div`
  display: none;
  ${props => props.theme.device.sm} {
    width: 100%;
    display: inline-block;
    font-family: TmoneyRoundWindRegular;
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #ff7e1f;
    margin-top: 1rem;
    span {
      margin-right: 2.8rem;
    }
  }
`;

export default MainReview;
